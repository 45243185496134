import { Boda } from '../../views/boda';
import { HomePage } from '../../views/homepage';
import { Services } from '../../views/services';
import { RouteItem } from './interfaces/navigation.interface';

export const RouterConfig: RouteItem[] = [
    {
        label: 'Inicio',
        listed: true,
        path: '/',
        Component: HomePage,
    },
    {
        label: 'Servicios',
        listed: true,
        path: '/services',
        Component: Services,
    },
    {
        listed: false,
        path: '/brideapp/myk',
        Component: Boda,
    },
];
