import React from "react";

export const Boda: React.FC = () => {
    window.location.replace('http://bks.boda.com.s3-website-us-west-1.amazonaws.com');

    return (
        <div style={{
            position:"absolute",
            background:"#333",
            width:"100vw",
            height:"100vh",
            zIndex:"10000"
        }}></div>
    );
}